<template>
    <div class="test">
        <q-page class="row" style="display: flex; justify-content: space-evenly;">
  

                <div class="col-12" style="display: flex; justify-content: center;">
                    <h4>
                        IRLs passées
                    </h4>
                </div>
  

                <div class="row col-10">


                    <q-card class="past-irl col-3">
                        <div class="card-container test">
                            <img src="https://cdn.quasar.dev/img/mountains.jpg" style="width: 100%;">

                            <q-card-section style="display:flex; flex-direction: column; align-items: center;">
                                <div class="text-h6">IRL 2023</div>
                                <div class="text-subtitle2">La ravauderie, du 28/06/2023 au 02/07/2023</div>
                            </q-card-section>

                            <q-card-section style="display:flex; flex-direction: column; align-items: center;">
                                <div class="text-irl">Une super IRL à la ravauderie, qui marquait les 14 ans de Mandragore, on a eu plein de participans c'était super.</div>
                                <q-btn label="Plus d'informations" :to="{name: 'Event2023'}" no-caps />
                            </q-card-section>
                        </div>
                    </q-card>

                    <q-card class="past-irl col-3">
                        <div class="card-container">
                            <img src="https://cdn.quasar.dev/img/mountains.jpg" style="width: 100%;">

                            <q-card-section style="display:flex; flex-direction: column; align-items: center;">
                                <div class="text-h6">IRL 2023</div>
                                <div class="text-subtitle2">La ravauderie, du 28/06/2023 au 02/07/2023</div>
                            </q-card-section>

                            <q-card-section style="display:flex; flex-direction: column; align-items: center;">
                                <div style="text-align: justify;">Une super IRL à la ravauderie, qui marquait les 14 ans de Mandragore, on a eu plein de participans c'était super.</div>
                                <q-btn label="Plus d'informations" :to="{name: 'Event2023'}" no-caps />
                            </q-card-section>
                        </div>
                    </q-card>

                    <q-card class="past-irl col-3">
                        <div class="card-container">
                            <img src="https://cdn.quasar.dev/img/mountains.jpg" style="width: 100%;">

                            <q-card-section style="display:flex; flex-direction: column; align-items: center;">
                                <div class="text-h6">IRL 2023</div>
                                <div class="text-subtitle2">La ravauderie, du 28/06/2023 au 02/07/2023</div>
                            </q-card-section>

                            <q-card-section style="display:flex; flex-direction: column; align-items: center;">
                                <div style="text-align: justify;">Une super IRL à la ravauderie, qui marquait les 14 ans de Mandragore, on a eu plein de participans c'était super.</div>
                                <q-btn label="Plus d'informations" :to="{name: 'Event2023'}" no-caps />
                            </q-card-section>
                        </div>
                    </q-card>

                    <q-card class="past-irl col-3">
                        <div class="card-container">
                            <img src="https://cdn.quasar.dev/img/mountains.jpg" style="width: 100%;">

                            <q-card-section style="display:flex; flex-direction: column; align-items: center;">
                                <div class="text-h6">IRL 2023</div>
                                <div class="text-subtitle2">La ravauderie, du 28/06/2023 au 02/07/2023</div>
                            </q-card-section>

                            <q-card-section style="display:flex; flex-direction: column; align-items: center;">
                                <div style="text-align: justify;">Une super IRL à la ravauderie, qui marquait les 14 ans de Mandragore, on a eu plein de participans c'était super.</div>
                                <q-btn label="Plus d'informations" :to="{name: 'Event2023'}" no-caps />
                            </q-card-section>
                        </div>
                    </q-card>

                    <q-card class="past-irl col-3">
                        <div class="card-container">
                            <img src="https://cdn.quasar.dev/img/mountains.jpg" style="width: 100%;">

                            <q-card-section style="display:flex; flex-direction: column; align-items: center;">
                                <div class="text-h6">IRL 2023</div>
                                <div class="text-subtitle2">La ravauderie, du 28/06/2023 au 02/07/2023</div>
                            </q-card-section>

                            <q-card-section style="display:flex; flex-direction: column; align-items: center;">
                                <div style="text-align: justify;">Une super IRL à la ravauderie, qui marquait les 14 ans de Mandragore, on a eu plein de participans c'était super.</div>
                                <q-btn label="Plus d'informations" :to="{name: 'Event2023'}" no-caps />
                            </q-card-section>
                        </div>
                    </q-card>

                    <q-card class="past-irl col-3">
                        <div class="card-container">
                            <img src="https://cdn.quasar.dev/img/mountains.jpg" style="width: 100%;">

                            <q-card-section style="display:flex; flex-direction: column; align-items: center;">
                                <div class="text-h6">IRL 2023</div>
                                <div class="text-subtitle2">La ravauderie, du 28/06/2023 au 02/07/2023</div>
                            </q-card-section>

                            <q-card-section style="display:flex; flex-direction: column; align-items: center;">
                                <div style="text-align: justify;">Une super IRL à la ravauderie, qui marquait les 14 ans de Mandragore, on a eu plein de participans c'était super.</div>
                                <q-btn label="Plus d'informations" :to="{name: 'Event2023'}" no-caps />
                            </q-card-section>
                        </div>
                    </q-card>

                    <q-card class="past-irl col-3">
                        <div class="card-container">
                            <img src="https://cdn.quasar.dev/img/mountains.jpg" style="width: 100%;">

                            <q-card-section style="display:flex; flex-direction: column; align-items: center;">
                                <div class="text-h6">IRL 2023</div>
                                <div class="text-subtitle2">La ravauderie, du 28/06/2023 au 02/07/2023</div>
                            </q-card-section>

                            <q-card-section style="display:flex; flex-direction: column; align-items: center;">
                                <div style="text-align: justify;">Une super IRL à la ravauderie, qui marquait les 14 ans de Mandragore, on a eu plein de participans c'était super.</div>
                                <q-btn label="Plus d'informations" :to="{name: 'Event2023'}" no-caps />
                            </q-card-section>
                        </div>
                    </q-card>

            </div>


        </q-page>
    </div>
  </template>


<style scoped>

.past-irl {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.irl-text {
    text-align: justify;
}

.q-card {
    box-shadow: none;
}

.card-container {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

</style>